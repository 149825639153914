figure {
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 30rem;
    @apply rounded-xl;
    @apply text-slate-400;
}

.name {
    @apply text-slate-200; 
}

figure.dark {
    @apply text-slate-800;
}

figure.dark .name { 
    @apply text-slate-900;
}