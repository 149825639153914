.app-white-elevated-section {
	@apply bg-slate-100 text-black pt-40 pb-40;
}

.app-white-elevated-section::before,
.app-white-elevated-section::after {
	--shadow-h: 6rem;
	content: '';
	position: absolute;
	height: var(--shadow-h);
	width: 100%;
	z-index: 1;
}
.app-white-elevated-section::before {
	background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.3));
	top: calc(-1 * var(--shadow-h));
}
