.train {
    overflow: hidden;
}
.train ul {
    transform: translateX(var(--transx));
    transition: transform linear 100ms;
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
}
