.ReactTags__tag {
    margin-top: 5px;
    margin-left: 10px;
    padding: 6px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--border, #EAEBE6);
    background: var(--light-bg, #FAFBF9);
    color: var(--black, #121315);
    height: 30px;

    /* Body Small */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 18.2px */


    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.tag-wrapper.ReactTags__tag {
    gap: 0;
   
}

.ReactTags__tag.active {
    border-radius: 4px;
    border: 1px solid rgba(26, 140, 245, 0.20);
    background: rgba(26, 140, 245, 0.05);
    color: var(--blue, #1A8CF5);

    /* Body Small */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 18.2px */
}

.ReactTags__tag.active .ReactTags__remove {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    background-color: #1A8CF555;
    border-radius: 9px;
    color: #1A8CF5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
}

.ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
}

.ReactTags__remove {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    background-color: #F3F4EF;
    border-radius: 9px;
    color: #999999;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
}

.ReactTags__tagInput {
    margin-top: 5px;
    padding: 6px;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--red, #F55);
    background: var(--light-bg, #FAFBF9);
    margin-left: 10px;
    height: 30px;
    color: var(--red, #F55);

    /* Body Small */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 18.2px */

    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.ReactTags__tagInput::before {
    display: block;
    width: 18px;
    height: 18px;
    content: ' ';
    background-size: 18px 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM12.1875 9.5625H9.5625V12.1875C9.5625 12.4984 9.3105 12.75 9 12.75C8.6895 12.75 8.4375 12.4984 8.4375 12.1875V9.5625H5.8125C5.502 9.5625 5.25 9.31087 5.25 9C5.25 8.68913 5.502 8.4375 5.8125 8.4375H8.4375V5.8125C8.4375 5.50163 8.6895 5.25 9 5.25C9.3105 5.25 9.5625 5.50163 9.5625 5.8125V8.4375H12.1875C12.498 8.4375 12.75 8.68913 12.75 9C12.75 9.31087 12.498 9.5625 12.1875 9.5625Z' fill='%23FF5555'/%3E%3C/svg%3E");
}

.ReactTags__tagInputField {
    border: none;
    outline: none;
    background: transparent;
}

.ReactTags__tagInputField::placeholder {
    background: var(--light-bg, #FAFBF9);
    color: var(--red, #F55);
}